import React from 'react'
import styled from 'styled-components'
import { config, animated, useSpring } from 'react-spring'
import Layout from '../components/layout'
import SEO from '../components/SEO'
import {AnimatedBox, Flex } from '../elements'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons'
import { faGrinBeam } from '@fortawesome/free-solid-svg-icons'

const Area = styled(animated.div)`
  display: grid;
  background-color: #e2e2e2;
  grid-template-columns: 1fr;
  grid-auto-rows: 100vh;

  @media (max-width: ${props => props.theme.breakpoints[1]}) {
    grid-template-columns: 1fr;
    grid-auto-rows: 70vh;
  }
  @media (max-width: ${props => props.theme.breakpoints[0]}) {
    grid-template-columns: 1fr;
    grid-auto-rows: 70vh;
  }

`

const PBox = styled(Flex)`  
    flex-direction: column;
    align-self: center;
    max-width: 1400px;
    background-color: #e2e2e2;
`

const ThankYou = styled(AnimatedBox)`  
    text-align: center;
    .fa-grin-beam {
        font-size: 5rem;
        color: #11589f;
        width: auto;
    }
    > div {
        margin: 0 auto;
        padding: ${props => props.theme.space[1]} ${props => props.theme.space[3]};
        
    }
    > h1 {
        
    }
    > hr {
        border: 0;
        background-color: #11589f;
        height: 1px;
        width: 50%;
        margin-left: auto;
        margin-right: auto;
    }
    
`

const OrderText = styled(AnimatedBox)`
    margin: 0 ${props => props.theme.space[3]};
    font-size: ${props => props.theme.fontSizes[1]};
    .fa-check-circle {
        font-size: 0.8rem;
        color: #11589f;
    }
    @media (max-width: ${props => props.theme.breakpoints[0]}) {
    margin: 0 2.5rem;
    white-space: nowrap;
    }
`

const Thanks  = () => {
  const pageAnimation = useSpring({
    config: config.slow,
    from: { opacity: 0 },
    to: { opacity: 1 },
  })

  const orderAnimation = useSpring({
    config: config.slow,
    from: { opacity: 0, transform: 'translate3d(-30px, 0, 0)' },
    to: { opacity: 1, transform: 'translate3d(0, 0, 0)'},
  })

  return (
    <Layout color="#e2e2e2">
      <SEO title="Order Complete | ASC Studio" />
      <Area>
      <PBox py={10} px={[6, 6, 8, 10]}>
            <ThankYou>
            <FontAwesomeIcon icon={faGrinBeam} />
            <animated.h1 style={pageAnimation}>Thank You!</animated.h1>
            <OrderText style={orderAnimation}> <FontAwesomeIcon icon={faCheckCircle} /> Your order has been placed.</OrderText>
            <hr />
            <div>
            <p>You will receive an email confirmation with your estimated delivery date shortly.</p> 
            <span>If you have any questions in relation to your order please <a href="/contact">contact us</a>.</span> 
            </div>
            </ThankYou>
      </PBox>
      </Area>
    </Layout>
  )
}

export default Thanks

